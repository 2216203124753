import React from 'react';
import { Modal } from 'antd';
import clx from 'classnames';

const ModalTraCuu = ({
  open,
  title,
  onOk,
  onCancel,
  children,
  ...props
}) => {

  return (
    <Modal
      {...props}
      className={clx("tra-cuu-modal", props.className)}
      title={title}
      centered
      open={open}
      onOk={() => onOk && onOk()}
      onCancel={() => onCancel && onCancel(false)}
      maskClosable={false}
    >
      { children }
    </Modal>
  )
}

export default ModalTraCuu