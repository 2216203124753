import { UrlWithoutLayouts } from 'constants/config';
import word from 'assets/images/word.svg';
import image from 'assets/images/image.svg';
import excel from 'assets/images/excel.svg';
import pdf from 'assets/images/pdf.svg';
import zip from 'assets/images/zip.svg';
import moment from 'moment';
import { includes, isEmpty, isArray } from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';
import { message, notification } from 'antd';
import messages from 'constants/messages';
import React from 'react'

export function retry(
  fn,
  retriesLeft = 5,
  interval = 1000
) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded')
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const isUrl = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
};

export const renderWithoutLayout = (url) => {
  if (UrlWithoutLayouts.includes(url)) {
    return true
  }
  return false
}

// Render icon file theo fileName
export const getImageExtension = (fileName) => {
  const lastDotPosition = fileName?.lastIndexOf('.');
  const fileType = fileName?.slice(lastDotPosition, fileName?.length)?.toLowerCase();
  switch (fileType) {
    case '.docx':
    case '.doc':
      return word;
    case '.xls':
    case '.xlsx':
      return excel;
    case '.pdf':
      return pdf;
    case '.zip':
    case '.rar':
      return zip;
    default:
      return image;
  }
}

export const getDate = (date) => {
  if (!date || !moment(date).isValid()) {
    return undefined;
  }
  return moment(date).utc().format()
}

export const exportFile = (result, fileName, extension) => {
  const hiddenElement = document.createElement('a');
  const url = window.URL || window.webkitURL;
  const blodFile = url.createObjectURL(result.data);
  hiddenElement.href = blodFile;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${fileName}${extension ? '.' + extension : ''}`;
  hiddenElement.click();
}

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

// Xử lý Type trạng thái
export const handleTypeTrangThai = (name) => {
  switch (name) {
    case 'DANG_XU_LY':
      return 'primary'
    case 'DANG_GIAI_QUYET':
      return 'solving';
    case 'DA_RUT_DON':
      return 'danger';
    case 'DA_HOAN_THANH':
      return 'success';
    case 'DA_XU_LY':
      return 'processing';
    case 'DA_GIAI_QUYET':
      return 'solved'
    case 'TU_THEO_DOI':
      return 'tracking';
    case 'CHUYEN_THEO_DOI':
      return 'primary';
    case 'DUOC_CHUYEN_THEO_DOI':
      return 'danger';
    default:
      return 'success';
  }
}

export const getFileResponse = (files) => {
  if (isEmpty(files)) {
    return undefined
  }
  const result = files?.map?.(item => {
    return {
      tenVanBan: item?.tenVanBan || item?.file?.summary || item?.file?.name || item?.name,
      fileId: item?.file?.id || item?.fileId,
      id: item?.id,
      vanBanNoiBo: item?.vanBanNoiBo,
      soVanBan: item?.soVanBan,
      ngayBanHanh: getDate(item?.ngayBanHanh)
    }
  })
  return result;
}

// Gọi api upload danh sách file
export const getPayloadFiles = async (files, requestApi) => {
  const isUploaded = file => !!file?.fileId || !!file?.file?.id;
  return Promise.all(
    files?.map(async (file) => {
      // Nếu file đã được upload thì trả về file hiện tại
      if (isUploaded(file)) {
        return file;
      }
      // Lấy thông tin upload file
      const fileBlob = file?.file?.originFileObj || file?.file;
      const formData = new FormData();
      formData.append('files', fileBlob)
      // Gọi API upload file
      const response = await requestApi(formData) || {};
      // Convert kết quả
      const newFile = {
        ...file,
        file: response?.data?.[0]
      };
      return newFile;
    }
    )
  ).then(result => {
    return result;
  }).catch(error => {
    notification.error({
      message: 'Thông báo',
      description: error?.message || messages.errorSystem
    })
  });
}

export const handlePreviewFile = async (fileId, fileName, chucDanhId) => {
  if (fileId) {
    const token = Cookies.get('accessToken');
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/files/${fileId}`,
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
        'chuc-danh-id': chucDanhId,
      },
      responseType: 'blob',
    })
      .then(response => {
        exportFile(response, fileName)
      })
      .catch(() => message.error({
        content: messages.cantDownFile,
        className: 'flex-message',
      }))
  }
}

// Render title cho column sort
export const renderTitleSort = (title) => {
  return (
    <span className='flex justify-between content-start'>
      <span>{title}</span>
      <i className='itt-sort  ml-1 text-[15px]' />
    </span>
  )
}

// Xử lý sort theo ngày tiếp nhận, người đứng đơn
export const handleSort = (setFilter) => (pagination, filters, sorter) => {
  const arrSort = [];
  const { field, order } = sorter;
  if (field === 'ngayTiepNhan') {
    if (order === 'ascend') {
      arrSort.push(`${field},asc`)
    }
    if (order === 'descend') {
      arrSort.push(`${field},desc`)
    }
  }
  if (field === 'ngayTiep') {
    if (order === 'ascend') {
      arrSort.push(`${field},asc`)
    }
    if (order === 'descend') {
      arrSort.push(`${field},desc`)
    }
  }
  if (field === 'nguoiDungDon') {
    if (order === 'ascend') {
      arrSort.push(`${field},asc`)
    }
    if (order === 'descend') {
      arrSort.push(`${field},desc`)
    }
  }
  if (field === 'nguonDon') {
    if (order === 'ascend') {
      arrSort.push(`${field},asc`)
    }
    if (order === 'descend') {
      arrSort.push(`${field},desc`)
    }
  }
  setFilter(prev => ({
    ...prev,
    sort: arrSort
  }))
};

// Disable chọn ngày lớn hơn ngày hiện tại
export const disabledGreaterThanCurrentDate = (current) => {
  return current && current > moment().endOf('day');
};

export const checkPermission = (listQuyen, currentQuyen) => {
  if (!currentQuyen) {
    return false;
  }
  if (listQuyen.includes('SUPERADMIN')) {
    return true;
  }
  return currentQuyen.some(item => includes(listQuyen, item))
}

export const getSoThuTu = (index, filter) => {
  return (index + 1) + ((filter?.page - 1) * filter?.size)
}

export const yearOptions = [];
for (let i = 0; i < 11; i++) {
  yearOptions.push({
    name: (moment().year() - i).toString(),
    value: (moment().year() - i).toString()
  });
}

export const convertNguonDon = (data) => {
  let result = data ? [...data] : [];
  if (isArray(result)) {
    result = result?.sort(function (a, b) { return a?.rankInList - b?.rankInList });
  }
  return result
}

export const removeUndefinedValue = (data) => {
  const result = { ...data };
  Object.keys(result).forEach(key => {
    if (result[key] === undefined) {
      delete result[key];
    }
  });
  return result
}