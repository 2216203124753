import { api } from "services/api"

export const congViecCanXuLyApi = api.injectEndpoints({
  endpoints: builder => ({
    getCongViecCanXuLy: builder.query({
      query: (params) => ({
        url: '/api/thong-bao/cong-viec-can-xu-ly',
        method: 'GET',
        params
      }),
    }),
  })
})

export const {
  useGetCongViecCanXuLyQuery,
  useLazyGetCongViecCanXuLyQuery
} = congViecCanXuLyApi;