import { exportFile } from "helpers"
import { api } from "services/api"

export const trangChuApi = api.injectEndpoints({
  endpoints: builder => ({
    getSoLieuThongKeTrangChu: builder.query({
      query: (params) => ({
        url: '/api/trang-chu/so-lieu-thong-ke',
        method: 'GET',
        params
      }),
    }),
    getSoLieuThongKeHoSoVuViec: builder.query({
      query: (params) => ({
        url: '/api/trang-chu/so-lieu-thong-ke/ho-so-vu-viec',
        method: 'GET',
        params
      }),
    }),
    getSoLieuThongKeSoTiepDan: builder.query({
      query: (params) => ({
        url: '/api/trang-chu/so-lieu-thong-ke/so-tiep-cong-dan',
        method: 'GET',
        params
      }),
    }),
    getSoCongViecCanXuLy: builder.query({
      query: () => ({
        url: '/api/trang-chu/cong-viec-can-xu-ly',
        method: 'GET'
      }),
    }),
    exportTiepCongDanTrangChu: builder.query({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/api/trang-chu/so-lieu-thong-ke/so-tiep-cong-dan/export-excel`,
          params,
          responseHandler: ((response) => response.blob())
        })
        exportFile(result, 'tiep-cong-dan', 'xlsx')
        return { data: null }
      }
    }),
    exportHoSoVuViecTrangChu: builder.query({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/api/trang-chu/so-lieu-thong-ke/ho-so-vu-viec/export-excel`,
          params,
          responseHandler: ((response) => response.blob())
        })
        exportFile(result, 'ho-so-vu-viec', 'xlsx')
        return { data: null }
      }
    }),
  })
})
export const {
  useGetSoLieuThongKeTrangChuQuery,
  useLazyGetSoLieuThongKeTrangChuQuery,
  useGetSoLieuThongKeHoSoVuViecQuery,
  useGetSoLieuThongKeSoTiepDanQuery,
  useGetSoCongViecCanXuLyQuery,
  useLazyExportTiepCongDanTrangChuQuery,
  useLazyExportHoSoVuViecTrangChuQuery
} = trangChuApi;
