import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataSearch: {
    phanHe: 'XU_LY_DON',
    timKiemTheo: 'DAY',
  },
  firstLoadGQD: false,
  firstLoadDinhKy: false,
  firstLoadGiaoViec: false,
}

const theoDoiGiamSatSlice = createSlice({
  name: 'theoDoiGiamSat',
  initialState,
  reducers: {
    setDataSearch: (state, action) => {
      state.dataSearch = action.payload;
    },
    setFirstLoadGQD: (state, action) => {
      state.firstLoadGQD = true;
    },
    setFirstLoadDinhKy: (state, action) => {
      state.firstLoadDinhKy = true;
    },
    setFirstLoadGiaoViec: (state, action) => {
      state.firstLoadGiaoViec = true;
    },
    resetData: (state) => {
      state.dataSearch = {}
    }
  },
});

export const { setDataSearch, resetData, setFirstLoadGQD, setFirstLoadDinhKy, setFirstLoadGiaoViec } = theoDoiGiamSatSlice.actions;
export const selectTheoDoiGiamSatSearch = (state) => state.theoDoiGiamSat.dataSearch;
export const selectLoadGQD = (state) => state.theoDoiGiamSat.firstLoadGQD;
export const selectLoadDinhKy = (state) => state.theoDoiGiamSat.firstLoadDinhKy;
export const selectLoadGiaoViec = (state) => state.theoDoiGiamSat.firstLoadGiaoViec;
export default theoDoiGiamSatSlice.reducer;

