import React, { useEffect, useState } from 'react';
import { Layout, Menu, Typography } from 'antd';
import PropTypes from 'prop-types';
import menuData from './menu';
import { useLocation, useNavigate } from 'react-router'
import { isArray, isEmpty, isNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentChucVuId, selectUser } from 'modal/authSlice';
import { checkPermission } from 'helpers';
import { useLazyGetCongViecCanXuLyQuery } from 'services/congViecCanXuLy';
import { resetData as resetDataSearchTheoDoiGiamSat } from 'modal/theoDoiGiamSatSlice';
import { resetData as resetDataSearchThongKeDonCu } from 'modal/thongKeDonCuSlice';
import { resetDataTraCuuDon } from 'modal/traCuuDonSlice';
import { authConfig } from 'constants/config';
import { resetDataQuanLyNguoiDung } from 'modal/quanLyNguoiDungSlide';

const { Sider } = Layout;
const { Title } = Typography;

const MasterSidebar = (props) => {

  const userInfo = useSelector(selectUser);
  const navigate = useNavigate();
  const { collapsed, setCollapsed } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const chucVuId = useSelector(selectCurrentChucVuId);

  const [currentMenu, setCurrentMenu] = useState([])

  const updateWindowDimensions = () => {
    setCollapsed(window.innerWidth < 1366);
  }

  const [getCongViecCanXuLy, { data: dataCongViecCanXuLy }] = useLazyGetCongViecCanXuLyQuery()

  useEffect(() => {
    window.addEventListener('resize', () => updateWindowDimensions());
  })

  useEffect(() => {
    setCollapsed(window.innerWidth < 1366);
  }, [setCollapsed])

  useEffect(() => {
    getCongViecCanXuLy()
  }, [chucVuId, getCongViecCanXuLy])

  useEffect(() => {
    // Get label
    const renderLabel = (key, label) => {
      let count = 0;
      switch (key) {
        case 'tcdthuongxuyen':
          count = dataCongViecCanXuLy?.tiepCongDanThuongXuyen;
          break;
        case 'tcddinhky':
          count = dataCongViecCanXuLy?.tiepCongDanDinhKy;
          break;
        case 'tcddotxuat':
          count = dataCongViecCanXuLy?.tiepCongDanDotXuat;
          break;
        case 'xulydon':
          count = dataCongViecCanXuLy?.xuLyGiaiQuyetDon;
          break;
        case 'theodoithuchien':
          count = dataCongViecCanXuLy?.theoDoiThucHien;
          break;
        default:
          count = 0;
          break;
      }
      return (
        <div className="flex justify-between items-center gap-1.5">
          <p className='flex justify-between gap-10'><p>{label}</p>{key === 'tiepcongdan' && (dataCongViecCanXuLy?.tiepCongDanThuongXuyen > 0 || dataCongViecCanXuLy?.tiepCongDanDinhKy > 0 || dataCongViecCanXuLy?.tiepCongDanDotXuat > 0) && (<span className='rounded-[30px] bg-[#FD4F54] w-[12px] text-center h-[12px] leading-[12px] p-1' />)}</p>
          {
            isNumber(count) && count > 0 &&
            <p className='rounded-[30px] bg-[#FD4F54] w-[30px] text-center h-[20px] leading-[20px] text-white text-[12px]'>{(count > 99) ? '99+' : count}</p>
          }
        </div>
      )
    }

    const getItemMenu = (data) => {
      const newData = data?.map((item) => {
        if (item.key === 'trangchu' || item.key === 'huongdansudung' || (item?.permissions && checkPermission(userInfo?.dsQuyen, item?.permissions))) {
          if (!isEmpty(item.children)) {
            return {
              ...item,
              children: getItemMenu(item.children),
              label: renderLabel(item?.key, item?.label)
            }
          }
          return {
            ...item,
            label: renderLabel(item?.key, item?.label)
          }
        }
        return null
      })
      return newData
    }
    if (menuData) {
      const menu = getItemMenu(menuData);
      setCurrentMenu(menu)
    }
  }, [userInfo?.dsQuyen, dataCongViecCanXuLy])

  const handleClick = (e) => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    }
    if (location.pathname === '/theo-doi-xu-ly-giai-quyet-don' || location.pathname === '/theo-doi-dinh-ky') {
      dispatch(resetDataSearchTheoDoiGiamSat());
    }
    if (location.pathname === '/thong-ke-don-cu') {
      dispatch(resetDataSearchThongKeDonCu());
    }
    if (location.pathname === '/tra-cuu-don') {
      dispatch(resetDataTraCuuDon());
    }
    if (location.pathname === '/quan-ly-nguoi-dung') {
      dispatch(resetDataQuanLyNguoiDung());
    }
    if (e.key === 'thanhtra') {
      window.location.href = `${authConfig?.REDIRECT_URI?.replace("/signin", '')}/thanh-tra`
    } else {
      navigate(e?.item?.props?.path);
    }
  }

  // Xử lý active menu
  const getSelectedKey = (menuData) => {
    if (!isArray(menuData)) {
      return [];
    }
    let selectedKey = [];
    menuData.forEach(item => {
      // Xử lý cho trường hợp item là 1 menu item
      if (!isArray(item?.children) && item?.activepaths?.includes(location?.pathname)) {
        selectedKey.push(item.key);
      }
      // Xử lý trường hợp item là một submenu
      if (isArray(item?.children)) {
        selectedKey.push(...getSelectedKey(item?.children));
      }
    });
    return selectedKey;
  };

  // Xử lý lấy OpenKeys menu
  const getOpenKeys = (menuData) => {
    if (!isArray(menuData)) {
      return [];
    }
    let openKeys = [];
    menuData.forEach(item => {
      if (isArray(item?.children)) {
        item?.children.forEach(childItem => {
          if (getSelectedKey(menuData)?.includes(childItem?.key)) {
            openKeys.push(item?.key);
          }
        });
        openKeys.push(...getOpenKeys(item?.children));
      }
    });
    return openKeys;
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={window.innerWidth < 768 ? 50 : 80}
      className="sidebar"
      width={window.innerWidth < 768 ? '100%' : 243}
    >
      <div className="logo flex justify-between">
        <img className="logo__img" src={`${process.env.PUBLIC_URL}/logo.png`} alt="" width={40} height={40} />
        {!collapsed && <Title className="logo__title !flex-1" ellipsis={{ rows: 1 }}>Thanh tra Đà Nẵng</Title>}
        {
          !collapsed && window.innerWidth < 768 && (
            <div className="text-white mr-2 cursor-pointer" onClick={() => setCollapsed(!collapsed)}>
              <span className="itt-trigger" />
            </div>
          )
        }
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={getSelectedKey(menuData)}
        defaultOpenKeys={getOpenKeys(menuData)}
        onClick={handleClick}
        items={currentMenu}
        className="menu"
      />
    </Sider>
  );
};

export default MasterSidebar;

MasterSidebar.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func
}

MasterSidebar.defaultProps = {
  collapsed: false,
  setCollapsed: null
}
